<template>
    <div v-if="!$store.state.auth.user">loading...</div>
    <div
        v-else
        id="__layout__content"
        :class="{
            'transitions-enabled': $store.state.layout.areTransitionsEnabled,
            'datepicker-popover-open': $store.state.layout.isDatepickerPopoverVisible,
        }"
    >
        <div class="relative flex min-h-screen">
            <Sidebar class="z-30" />
            <div class="relative flex-grow z-20">
                <Header class="z-10" />
                <Nuxt class="relative z-0" />
            </div>
        </div>

        <GridOverlay />

        <ClientOnly>
            <Toast position="top-right" />
            <ConfirmModal />
            <TableModal :is-open="$store.state.table.isTableModalOpen" :close="closeCreateTableModal" />
        </ClientOnly>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce';
import Toast from 'primevue/toast';
import Sidebar from '../components/Layout/Sidebar';
import TableModal from '../components/Modals/TableModal';
import ConfirmModal from '~/components/Modals/ConfirmModal';
import GridOverlay from '~/components/Layout/GridOverlay';
import Header from '~/components/Layout/Header';

export default {
    name: 'Default',
    components: {
        Toast,
        GridOverlay,
        Header,
        Sidebar,
        TableModal,
        ConfirmModal,
    },
    middleware: ['redirect-user'],
    data() {
        return {
            expiryCookies: {
                healthBook: {
                    lastDisplayedDate: {
                        key: 'health_book_expiry_warning_last_displayed_date',
                    },
                    displayFrequency: {
                        key: 'health_book_expiry_warning_display_frequency',
                    },
                },
                lungScreening: {
                    lastDisplayedDate: {
                        key: 'lung_screening_expiry_warning_last_displayed_date',
                    },
                    displayFrequency: {
                        key: 'lung_screening_expiry_warning_frequency',
                    },
                },
            },
        };
    },
    mounted() {
        window.addEventListener('resize', this.lockWrapperWidthTransition);
        window.addEventListener('resize', this.unlockWrapperWidthTransition);

        // Display warning to user if their health book or lung screening is about to expire
        setTimeout(() => {
            if (!this.$user) {
                return;
            }

            this.expiryCookies.healthBook.lastDisplayedDate.value = this.getDateValidatedCookieValue(
                this.expiryCookies.healthBook.lastDisplayedDate.key
            );
            this.expiryCookies.healthBook.displayFrequency.value = this.getExpiryTypeValidatedCookieValue(
                this.expiryCookies.healthBook.displayFrequency.key
            );
            this.expiryCookies.lungScreening.lastDisplayedDate.value = this.getDateValidatedCookieValue(
                this.expiryCookies.lungScreening.lastDisplayedDate.key
            );
            this.expiryCookies.lungScreening.displayFrequency.value = this.getExpiryTypeValidatedCookieValue(
                this.expiryCookies.lungScreening.displayFrequency.key
            );

            this.checkExpiry('healthBook', !!this.$user.health_book_expiry, this.$user.days_until_health_book_expiry);

            this.checkExpiry(
                'lungScreening',
                !!this.$user.lung_screening_expiry,
                this.$user.days_until_lung_screening_expiry
            );
        }, 1000);

        // const memberIds = ['63b1e7a611287d4063d0567a', '63b1ebd04cfe04f218c99924', '63b0346a660c2dea1c3384ba'];
        // const pool = [
        //     'kenyér',
        //     'csirke',
        //     'uborka',
        //     'retek',
        //     'hagymás palacsinta',
        //     'tepertős tiramisu',
        //     'kukorica',
        //     'kecske',
        //     'hajaj',
        //     'sejhaj',
        // ];
        //
        // let counter = 0;
        //
        // const rHour = () => {
        //     return String(Math.ceil(Math.random() * 23)).padStart(2, '0');
        // };
        //
        // const rDay = () => {
        //     return String(Math.ceil(Math.random() * 28)).padStart(2, '0');
        // };
        //
        // const rMonth = () => {
        //     return String(Math.ceil(Math.random() * 12)).padStart(2, '0');
        // };
        //
        // if (this.$store.state.auth.user.role === 1) {
        //     setInterval(() => {
        //         this.$axios
        //             .$post('/api/record', {
        //                 created_at_mutable: `2022-${rMonth()}-${rDay()}T${rHour()}:39:24.855Z`,
        //                 table_id: '63b1e714acafcce79bf2e962',
        //                 data: {
        //                     szoveges_mezo: pool[Math.floor(Math.random() * pool.length)],
        //                     szoveges_mezo_auto: pool[Math.floor(Math.random() * pool.length)],
        //                     datum: `2023-${rMonth()}-${rDay()}T${rHour()}:39:24.855Z`,
        //                     oraperc_v: `2023-01-01T${rHour()}:33:24.855Z`,
        //                     csapattag: memberIds[Math.floor(Math.random() * 3)],
        //                     hofok: Math.floor(Math.random() * 10 + 1),
        //                 },
        //             })
        //             .then((res) => {
        //                 if (res.success === 1) {
        //                     counter++;
        //                     console.log('ok ' + counter);
        //                 }
        //             });
        //     }, 500);
        // }
    },
    methods: {
        checkExpiry(key, expiryDate, daysUntilExpiry) {
            const currentWarningDisplayFrequency = this.getWarningDisplayFrequency(daysUntilExpiry);

            const expiresWithin30Days = expiryDate && daysUntilExpiry < 30;

            if (
                expiresWithin30Days &&
                (!this.expiryCookies[key].lastDisplayedDate.value ||
                    this.expiryCookies[key].displayFrequency.value !== currentWarningDisplayFrequency ||
                    this.$dayjs().diff(this.$dayjs(this.expiryCookies[key].lastDisplayedDate.value), 'day') >=
                        currentWarningDisplayFrequency)
            ) {
                this.$toast.add({
                    summary: 'Emlékeztető',
                    detail: this.getExpiryMessage(key, daysUntilExpiry),
                    severity: 'warn',
                    life: 10000,
                });

                this.$cookies.set(this.expiryCookies[key].lastDisplayedDate.key, this.$dayjs().format('YYYY-MM-DD'), {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 30,
                });

                this.$cookies.set(this.expiryCookies[key].displayFrequency.key, currentWarningDisplayFrequency, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 30,
                });
            } else if (!expiresWithin30Days) {
                this.$cookies.remove(this.expiryCookies[key].lastDisplayedDate.key);
                this.$cookies.remove(this.expiryCookies[key].displayFrequency.key);
            }
        },
        getDateValidatedCookieValue(cookieKey) {
            const _cookieValue = this.$cookies.get(cookieKey);

            if (this.$dayjs(_cookieValue, 'YYYY-MM-DD', true).isValid()) {
                return _cookieValue;
            }

            return null;
        },
        getExpiryTypeValidatedCookieValue(cookieKey) {
            const _cookieValue = this.$cookies.get(cookieKey);

            if ([1, 3, 5].includes(_cookieValue)) {
                return _cookieValue;
            }

            return null;
        },
        getWarningDisplayFrequency(daysLeft) {
            if (isNaN(daysLeft)) {
                return 1;
            }

            return daysLeft < 3 ? 1 : daysLeft < 7 ? 3 : 5; // day(s)
        },
        getExpiryMessage(type, daysUntilExpiry) {
            if (isNaN(daysUntilExpiry)) {
                return '';
            }

            let msg = '';

            if (type === 'healthBook') {
                msg = 'Egészségügyi könyved érvényessége';
            }

            if (type === 'lungScreening') {
                msg = 'Tüdőszűrésed érvényessége';
            }

            if (daysUntilExpiry < 0) {
                msg += ` ${Math.abs(daysUntilExpiry)} napja lejárt!`;
            } else if (daysUntilExpiry === 0) {
                msg += ' ma lejár';
            } else if (daysUntilExpiry === 1) {
                msg += ' holnap lejár';
            } else if (daysUntilExpiry === 2) {
                msg += ' holnapután lejár';
            } else {
                msg += ` ${daysUntilExpiry} nap múlva lejár`;
            }

            return msg;
        },
        closeCreateTableModal() {
            this.$store.commit('table/setIsTableModalOpen', false);
        },
        lockWrapperWidthTransition: debounce(500, true, function () {
            this.$store.commit('layout/setAreTransitionsEnabled', false);
        }),
        unlockWrapperWidthTransition: debounce(500, function () {
            this.$store.commit('layout/setAreTransitionsEnabled', true);
        }),
    },
};
</script>
